body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre.code {
  font-family: "PT Mono", "Courier New", Courier, monospace;
  font-size: 10px;
  display: inline-block;
  max-width: 400px;
  overflow-x: scroll;
}
